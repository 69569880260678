.dedication {
  margin-top: 10px;
}

.generation-form {
  .form-group {
    margin-bottom: 1px;
  }

  margin-top: 15px;
  margin-bottom: 15px;
}

.generation-selection {
  padding-left: 15px;
}
