@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";

.entered-container {
  height: 100vh;
  overflow-y: auto;
  max-width: 720px;
  text-align: center;
}

.entered-container::-webkit-scrollbar {
  //WebKit
  width: 0;
  height: 0;
}

.game-container {
  align-items: center;
  display: flex;
  flex: auto;
  flex-direction: column;
  height: 100%;
  margin: 0 auto;
}

.game-header {
  .game-count {
    button {
      position: absolute;
      left: 4px;
      //Vertically center the icon
      top: 50%;
      transform: translateY(-50%);
    }

    position: relative;
  }

  width: 100%;
  max-width: 720px;
}

.pokemon {
  display: inline-block;
  height: 140px; //img height + room for number and name
  width: 72px; //img width
  margin: 0 auto;

  //Invisble border for alignment
  img {
    border: medium solid theme-color("dark");
    border-radius: 25px;
  }

  .pokemon-name {
    font-size: x-small;
  }
}

//Make border visible only for last valid entered Pokemon
.last-valid {
  img {
    border: medium dashed theme-color("light");
    border-radius: 25px;
  }
}

.input-container {
  max-width: 400px;
  //Overwrite p-3 padding just for the top to give space for the suggestion
  padding-top: 5px !important;
  width: 100%;

  .suggestion {
    height: 20px;
    margin-bottom: 8px;
  }
}
